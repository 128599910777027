import {Box, Typography, useTheme} from '@mui/material';

import {Colors} from '../common/colors';
import {FirstHomeTitle} from '../common/text';
import {LogoHome} from '../navigation/logo';
import TitleEn from '../icons/svg/title_en.svg';
import TitleFr from '../icons/svg/title_fr.svg';
import {useTranslation} from 'next-i18next';

export default function TitleHome() {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {t} = useTranslation('common');

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                px: {xs: '10px', md: '20px', lg: 0},
                py: {xs: '50px', md: '100px'},
                alignItems: 'center',
                textAlign: 'center',
            }}
            maxWidth="1280px"
            margin="auto"
        >
            <Box
                sx={{
                    textAlign: 'center',
                    width: '90%',
                    maxWidth: 500,
                }}
            >
                <LogoHome />
            </Box>
            {isDark && <FirstHomeTitle>{t('tips.preorder')}</FirstHomeTitle>}
            {!isDark && <SeoSvgTitle />}
            <Typography
                sx={{
                    color: isDark ? Colors.somber : 'black',
                    fontSize: {
                        xs: '12px',
                        sm: '14px',
                        md: '18px',
                        lg: '26px',
                    },
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
            >
                {t('tips.participate')}
            </Typography>
        </Box>
    );
}

function SeoSvgTitle() {
    const {t, i18n} = useTranslation('common');

    const fr = i18n.language == 'fr';

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Typography
                variant="h1"
                component="h1"
                sx={{
                    fontWeight: 'bold',
                    fontSize: '59px',
                    width: '100%',
                }}
            >
                <>
                    {fr && <TitleFr />}
                    {!fr && <TitleEn />}
                </>
            </Typography>
        </Box>
    );
}
